import { useEffect, useState } from 'react';
import { Button, Table as BTable } from 'react-bootstrap';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { IStaffInfoResponse } from '../../features/staff/models/IStaff';
import { useDeleteStaffMutation, useGetStaffQuery } from '../../features/staff/services/staffSlice';
import { useAuth } from '../../app/useAuth';



const StaffGridDisplay = () => {
  const auth = useAuth();
  const { isFetching: isFetchingStaff, data: staffData } = useGetStaffQuery();
  const [data, setData] = useState<IStaffInfoResponse[]>(staffData ?? []);
  const [deleteStaffFn] = useDeleteStaffMutation();

  
const columnHelper = createColumnHelper<IStaffInfoResponse>();

const columns = [
  columnHelper.accessor('staffId', {
    header: 'Staff ID',
    cell: info => info.getValue(),
    // footer: info => info.column.id,
  }),
  columnHelper.accessor('firstName', {
    header: 'First Name',
    cell: info => info.getValue(),
    // footer: info => info.column.id,
  }),
  columnHelper.accessor(row => row.lastName, {
    id: 'lastName',
    cell: info => <span>{info.getValue()}</span>,
    header: () => <span>Last Name</span>,
    // footer: info => info.column.id,
  }),
  columnHelper.accessor(row => row.mobileNumber, {
    id: 'mobileNumber',
    cell: info => <span>{info.getValue()}</span>,
    header: () => <span>Mobile Number</span>,
    // footer: info => info.column.id,
  }),
  columnHelper.accessor('designation', {
    header: () => <span>Designation</span>,
    // footer: info => info.column.id,
  }),
  columnHelper.accessor('type', {
    header: () => <span>Staff Type</span>,
    // footer: info => info.column.id,
  }),
  columnHelper.accessor('staffId', {
    header: () => <span>Delete Record</span>,
    cell: ({cell}) => (
      <Button onClick={() => deleteStaff(cell.row.getValue('staffId'), cell.row.getValue('firstName'), cell.row.getValue('lastName'))}>Delete</Button>
    )
    // footer: info => info.column.id,
  }),
]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    if (staffData !== undefined) {
      setData(staffData);
    }
  }, [staffData]);


  function deleteStaff(staffId: number, fname:string, lname: string) {
    let ans = window.confirm(`Are you sure you want to delete ${fname} ${lname}?`);
    console.log(staffId, ans);
    if (ans) {
      let idx = data.findIndex(itm => itm.staffId=== staffId);
      if (idx > -1) {
        // TODO: delete role only for specific people. hardcoded here for now!
        if (auth.user?.username === '20000099' || auth.user?.username === '20100022'){
          // delete confirmed.
          deleteStaffFn(data[idx].id);
        } else {
          window.alert('You are not allowed to delete. Only Rajesh sir is allowed to delete records.');
        }
      } else {
        window.alert(`Staff ${staffId} not found!`);
      }

    }
  }

  return (
    <div className="p-2">
      {isFetchingStaff ? <div>Loading...</div> :
        <BTable striped bordered hover responsive size={'sm'}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={`${row.getValue('staffId')}`}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>

        </BTable>}
      {/* <div className="h-4" /> */}
      {/* <button onClick={() => rerender()} className="border p-2">
        Rerender
      </button> */}
    </div>
  )
}

export default StaffGridDisplay;