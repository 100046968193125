import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '../../../common/BaseQueryWithReAuth';
import { TAGS } from '../../../constants/app-constants';
import { REPORTS_GEN_STAFF_ATTENDANCE_GET_URL, REPORTS_GEN_STUDENT_ATTENDANCE_TRANSPORT_GET_URL } from '../../../constants/service-constants';


export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [TAGS.Reports],
  endpoints: (builder) => ({
    generateStudentAttendanceReport: builder.query<void, void>({
      query: () => `${REPORTS_GEN_STUDENT_ATTENDANCE_TRANSPORT_GET_URL}`,
      providesTags: [TAGS.Reports],
    }),

    generateStaffAttendanceReport: builder.query<void, void>({
      query: () => `${REPORTS_GEN_STAFF_ATTENDANCE_GET_URL}`,
      providesTags: [TAGS.Reports],
    }),
    
  }),

});

export const { useLazyGenerateStaffAttendanceReportQuery, useLazyGenerateStudentAttendanceReportQuery } = reportsApi
