import { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useLazyGenerateStaffAttendanceReportQuery, useLazyGenerateStudentAttendanceReportQuery } from './services/reportsSlice';


const AllReports = () => {
    // const { data, error, isLoading, refetch } = useGetBusRoutesQuery();
    const [genStudentReport, {isSuccess: genStudentSuccess, isLoading: isLoadingStudentReport}] = useLazyGenerateStudentAttendanceReportQuery();
    const [genStaffReport, {isSuccess: genStaffSuccess, isLoading: isLoadingStaffReport}] = useLazyGenerateStaffAttendanceReportQuery();
    const [msgStudent, setMsgStudent] = useState<string>('');
    const [msgStaff, setMsgStaff] = useState<string>('');

    useEffect(() => {
      if (genStudentSuccess) {
        setMsgStudent('Student Report emailed successfully to your @vrsvjschool.com email address');
      }
      if (genStaffSuccess) {
        setMsgStaff('Staff Report emailed successfully to your @vrsvjschool.com email address');
      }
    }, [genStudentSuccess, genStaffSuccess]);

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginBottom: "20px",
                }}
            >
                <Card style={{ textAlign: "left", width: "100%" }}>
                    <Card.Header>Reports</Card.Header>
                    <Card.Body>
                        <Card.Subtitle className="mb-2 text-muted">
                            <div>{msgStudent}</div>
                        </Card.Subtitle>
                            <Button onClick={() => {setMsgStudent(''); genStudentReport();}} disabled={isLoadingStudentReport}>
                                {isLoadingStudentReport ? 'Processing...' : 'Email Student Attendance and Transport Report'}
                            </Button>
                            <br/>
                            <br/>
                            <div>{msgStaff}</div>
                            <Button onClick={() => {setMsgStaff(''); genStaffReport(); }} disabled={isLoadingStaffReport}>
                                {isLoadingStaffReport ? 'Processing...' : 'Email Staff Attendance Report'}
                            </Button>
                    </Card.Body>
                    <Card.Footer>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "baseline",
                            }}
                        >
                        </div>
                    </Card.Footer>
                </Card>

            </div>
        </>
    )

}


export default AllReports;