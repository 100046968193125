import React, { useState } from 'react';
// import './login.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../../app/authSlice';
import { useLoginMutation, useValidateOtpMutation } from './service/loginApiSlice';
import { ILoginResponse, IValidateOtpRequest } from './models/ILoginModels';
import { Button } from 'react-bootstrap';



export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [formState, setFormState] = React.useState<{ username: string, password: string, otp: number }>({ username: '', password: '', otp: 0 });
  const [login, { isLoading: loginIsLoading }] = useLoginMutation();
  const [validateOtp, { isLoading: validateOtpIsLoading }] = useValidateOtpMutation();
  const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    console.log('handlechange', name, value);
    setFormState((prev) => ({ ...prev, [name]: value }))
  };
  const [optSent, setOtpSent] = useState<boolean>(false);
  const [loginResponse, setLoginResponse] = useState<ILoginResponse>();

  const loginButtonClicked = async () => {
    console.log('login clicked', formState);
    try {
      const loginResponse = await login({ username: formState.username, password: formState.password }).unwrap();
      setLoginResponse(loginResponse);
      if (loginResponse.isOTPSent) {
        setOtpSent(true);
      }
    } catch (err) {
      console.log(`error! `, err);
      let msg = 'unknown';
      if (typeof err === 'string') {
        msg = err.toUpperCase();
      } else if (err instanceof Error) {
        msg = err.message;
      } else {
        msg = (err as { data: string }).data;
      }
      alert(msg);
    }
  };

  const validateOtpButtonClicked = async () => {
    try {
      let payload: IValidateOtpRequest = {
        username: formState.username,
        password: formState.password,
        smsJobno: loginResponse?.smsJobNo ?? 0,
        otp: formState.otp
      }      
      console.log('validateOtpButtonClicked', payload);
      let resp = await validateOtp(payload).unwrap();
      dispatch(setCredentials(resp));
      navigate(location.state?.path ?? '/');
    } catch (err) {
      console.log(`error! `, err);
      let msg = 'unknown';
      if (typeof err === 'string') {
        msg = err.toUpperCase();
      } else if (err instanceof Error) {
        msg = err.message;
      } else {
        msg = (err as { data: string }).data;
      }

      alert(msg);
    }

  }


  return (

    <>
      <section className="text-center text-lg-start">
        <div className="container py-4">
          <div className="row g-0 align-items-center">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <div className="card" style={{background: 'hsla(0, 0%, 100%, 0.55)', backdropFilter: 'blur(30px)'}}>
                <div className="card-body p-5 shadow-5 text-center">
                  <h2 className="fw-bold mb-5">VRS Vignana Jyothi School Admin Portal</h2>
                  <form autoComplete='off'>
                    
                    <div className="form-outline mb-4">
                      <input type="text" name='username'  onChange={(e) => handleChange(e)} id="form3Example3" className="form-control" />
                      <label className="form-label" htmlFor="form3Example3">Staff ID Number</label>
                    </div>

                    <div className="form-outline mb-4">
                      <input type="password" name='password' onChange={(e) => handleChange(e)} id="form3Example4" className="form-control" />
                      <label className="form-label" htmlFor="form3Example4">Registered Phonenumber</label>
                    </div>

                    <Button onClick={() => loginButtonClicked()} className="btn btn-primary btn-block mb-4" disabled={loginIsLoading || validateOtpIsLoading || optSent}>
                      Login
                    </Button>


                    {
                      optSent ? 
                      <>
                        <hr/>
                        <div className="form-outline mb-4">
                          <input type="text" name='otp' onChange={handleChange} id="form3Example3" className="form-control" />
                          <label className="form-label" htmlFor="form3Example3">OTP</label>
                        </div>
                        <Button onClick={() => validateOtpButtonClicked()} className="btn btn-primary btn-block mb-4" disabled={loginIsLoading || validateOtpIsLoading}>
                          Validate OTP
                        </Button>
                      </>
                      : null
                    }

                    
                  </form>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-6 mb-5 mb-lg-0">
              <img src="https://mdbootstrap.com/img/new/ecommerce/vertical/004.jpg" className="w-100 rounded-4 shadow-4"
                alt="" />
            </div> */}
          </div>
          
        </div>

      </section>
    </>




    // <section classNameName="vh-100 gradient-custom">
    //   <div classNameName="container py-5 h-100">
    //     <div classNameName="row d-flex justify-content-center align-items-center h-100">
    //       <div classNameName="col-12 col-md-8 col-lg-6 col-xl-5">
    //         <div classNameName="card bg-dark text-white" style={{ borderRadius: '1rem' }}>
    //           <div classNameName="card-body p-5 text-center">

    //             <div classNameName="mb-md-5 mt-md-4 pb-5">

    //               <h2 classNameName="fw-bold mb-2 text-uppercase">Login</h2>
    //               {/* <p classNameName="text-white-50 mb-5">Please enter your login and password!</p> */}

    //               <div classNameName="form-outline form-white mb-4">
    //                 <label classNameName="form-label" htmlFor="typeEmailX">Staff Id Number</label>
    //                 <input type="text" name='username' id="typeEmailX" classNameName="form-control form-control-lg" placeholder='Staff ID Number' />
    //               </div>

    //               <div classNameName="form-outline form-white mb-4">
    //                 <label classNameName="form-label" htmlFor="typePasswordX">Password</label>
    //                 <input type="password" name='password' id="typePasswordX" classNameName="form-control form-control-lg" placeholder='Registered Phonenumber' />
    //               </div>

    //               {/* <p classNameName="small mb-5 pb-lg-2"><a classNameName="text-white-50" href="#!">Forgot password?</a></p> */}

    //               <button classNameName="btn btn-outline-light btn-lg px-5" type="submit" onClick={() => loginButtonClicked()} disabled={loginIsLoading || validateOtpIsLoading}>Login</button>

    //             </div>

    //             {/* <div>
    //           <p classNameName="mb-0">Don't know what to do? <a href="#!" classNameName="text-white-50 fw-bold">Sign Up</a>
    //           </p>
    //         </div> */}

    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  )
}

export default Login;
